<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Detail Data Surat Kuasa</h4>
          </template>
          <template v-slot:body>
            <div class="d-inline-block float-right">
              <b-button
                class="rounded px-4 float-right"
                variant="secondary"
                size="sm"
                @click="$router.go(-1)"
                ><i class="ri-arrow-left-line m-0"></i>Kembali</b-button
              >
            </div>
            <div class="align-items-center mt-lg-5">
              <b-form>
                <!-- <b-form-group>
                  <label for="nomorSuratKuasa">Nomor Surat Kuasa:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="nomorSuratKuasa"
                    v-model="form.nomorSuratKuasa"
                    required
                    placeholder="Nomor Surat Kuasa"
                    max-length="100"
                    disabled
                    isDetail
                  />
                </b-form-group> -->
                <b-form-group>
                  <label for="tanggalSurat">Tanggal Surat:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="tanggalSurat"
                    ref="refTanggalSurat"
                    :config="basicConfig"
                    v-model="form.tanggalSurat"
                    placeholder="Tanggal Surat"
                    disabled
                    isDetail
                  />
                </b-form-group>
                <b-row class="mt-4">
                  <!--  PIHAK PERTAMA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK PERTAMA</h4>
                    <b-form-group>
                      <label for="namaPihakSatu">Nama Pemberi Kuasa:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="namaPihakSatu"
                        v-model="form.namaPihakSatu"
                        placeholder="Nama Pemberi Kuasa"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="nipPihakSatu">NIP:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="nipPihakSatu"
                        v-model="form.nipPihakSatu"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakSatu">Jabatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jabatanPihakSatu"
                        v-model="form.jabatanPihakSatu"
                        placeholder="Jabatan"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakSatu">Instansi:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="instansiPihakSatu"
                        v-model="form.instansiPihakSatu"
                        placeholder="Instansi"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <!-- <b-form-group>
                      <label for="alamatPihakSatu">Alamat:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="alamatPihakSatu"
                        v-model="form.alamatPihakSatu"
                        disabled
                        isDetail
                        type="textarea"
                        max-length="255"
                      />
                    </b-form-group> -->
                  </b-col>
                  <!-- PIHAK KEDUA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK KEDUA</h4>
                    <b-form-group>
                      <label for="namaPihakDua">Nama Pihak Kedua:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="namaPihakDua"
                        v-model="form.namaPihakDua"
                        placeholder="Nama Pihak Kedua"
                        max-length="100"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="nipPihakDua">NIP:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="nipPihakDua"
                        v-model="form.nipPihakDua"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakDua">Jabatan:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="jabatanPihakDua"
                        v-model="form.jabatanPihakDua"
                        placeholder="Jabatan"
                        max-length="100"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakDua">Instansi:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="instansiPihakDua"
                        v-model="form.instansiPihakDua"
                        placeholder="Instansi"
                        max-length="100"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <!-- <b-form-group>
                      <label for="alamatPihakDua">Alamat:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="alamatPihakDua"
                        v-model="form.alamatPihakDua"
                        disabled
                        isDetail
                        max-length="255"
                      />
                    </b-form-group> -->
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- KEGIATAN -->
                  <b-col>
                    <h4 class="mb-2">KEGIATAN</h4>
                    <b-form-group>
                      <label for="namaKegiatan">Tujuan Pemberi Kuasa:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="namaKegiatan"
                        v-model="form.namaKegiatan"
                        disabled
                        isDetail
                        placeholder="Nama Kegiatan"
                        max-length="255"
                      />
                    </b-form-group>
                    <!-- <b-form-group>
                      <label for="judulKegiatan">Judul Kegiatan:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="judulKegiatan"
                        v-model="form.judulKegiatan"
                        placeholder="Judul Kegiatan"
                        max-length="255"
                        disabled
                        isDetail
                      />
                    </b-form-group> -->
                    <!-- <b-form-group>
                      <label for="tanggalAwalKegiatan"
                        >Tangga Mulai Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tanggalAwalKegiatan"
                        v-model="form.tanggalAwalKegiatan"
                        placeholder="Tangga Mulai Kegiatan"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="tanggalAkhirKegiatan"
                        >Tanggal Selesai Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tanggalAkhirKegiatan"
                        v-model="form.tanggalAkhirKegiatan"
                        placeholder="Tanggal Selesai Kegiatan"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="tempatKegiatan">Tempat Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tempatKegiatan"
                        v-model="form.tempatKegiatan"
                        placeholder="Tempat Kegiatan"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group> -->
                    <!-- Lokasi Dokumen -->
                    <b-row>
                      <b-col
                        id="lokasiDokumenSk"
                        class="align-self-center mt-2"
                        :class="
                          dataDokumenKs
                            ? 'cursor-pointer'
                            : 'cursor-not-allowed'
                        "
                        md="4"
                        v-b-tooltip="
                          dataDokumenKs
                            ? 'Lihat Dokumen'
                            : 'File tidak ditemukan'
                        "
                        :style="{ marginTop: '-15px' }"
                      >
                        <label for="lokasiDokumenSk" class="mr-2"
                          >File Dokumen:</label
                        >
                        <b-button
                          v-if="dataDokumenKs"
                          variant="primary"
                          @click="showDokumenKs"
                          ><i class="ri-search-line mr-1"></i>Lihat
                          Dokumen</b-button
                        >
                        <b-button v-else variant="default" disabled
                          ><i class="ri-search-line mr-1"></i>Lihat
                          Dokumen</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <!-- Validasi -->
                <b-row class="mt-2">
                  <b-col>
                    <h4 class="mb-2">VALIDASI DATA</h4>
                    <b-form-group>
                      <input-datalists
                        id="idStatusValidasi"
                        class="w-full text-sm"
                        placeholder="Pilih Validasi"
                        v-model="form.idStatusValidasi"
                        label="keteranganValidasi"
                        required
                        :options="listDataValidasi || []"
                        :reduce="
                          listDataValidasi => listDataValidasi.idValidasi
                        "
                        :clearable="false"
                      >
                        <template #header>
                          <label class="block"
                            >Status Validasi:
                            <span class="text-danger">*</span></label
                          >
                        </template>
                        <template v-slot:option="option">
                          <div class="flex justify-between p-1">
                            {{
                              option.keteranganValidasi == null
                                ? `Data tidak ditemukan`
                                : option.idValidasi +
                                  ` | ` +
                                  option.keteranganValidasi
                            }}
                          </div>
                        </template>
                      </input-datalists>
                    </b-form-group>
                    <b-form-group v-if="form.idStatusValidasi == 2">
                      <label for="idKeteranganValidasi">Alasan Pemutusan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="idKeteranganValidasi"
                        placeholder="Masukkan alasan pemutusan"
                        v-model="form.idKeteranganValidasi"
                        required
                        type="textarea"
                        max-length="255"
                        rows="3"
                      />
                    </b-form-group>
                    <!-- <b-form-group v-else>
                      <label for="idStatusValidasi">Status Validasi:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="idStatusValidasi"
                        v-model="form.idStatusValidasi"
                        required
                        isDetail
                      />
                    </b-form-group> -->
                  </b-col>
                </b-row>
                <!-- Button -->
                <b-row class="mt-5">
                  <b-col>
                    <b-button
                      class="btn-block rounded mx-1"
                      variant="secondary"
                      size="sm"
                      @click="$router.go(-1)"
                      ><i class="ri-arrow-left-line m-0"></i>Kembali</b-button
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      class="btn-block rounded mx-1"
                      variant="primary"
                      v-b-tooltip="'Validasi data'"
                      size="sm"
                      @click="validasiData"
                      :disabled="!isValidForm"
                      ><i class="ri-check-line m-0"></i>Validasi</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'kerjasama.sk.detail',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.idStatusValidasi && this.form.idStatusValidasi === 2
          ? this.form.idKeteranganValidasi
          : !this.form.idKeteranganValidasi != null
      return status
    }
  },
  mounted() {
    if (!this.$route.params.idSk) this.$router.go(-1)
    this.form.idSk = this.$route.params.idSk
    this.statusValidasi = this.$route.params.statusValidasi
    this.keteranganValidasi = this.$route.params.keteranganValidasi
    this.innitData()
  },
  methods: {
    /**
     * Get data Detail Kerjasama MoU
     */
    async getDataTransaksiSk_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDataSuratKuasa?idSk=${this.form.idSk}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataSk = res.data[0]
          this.form = { ...this.dataSk }
          this.form.lokasiDokumenSk = null
          // Dokumen KS
          this.getDokumenKs_()
          /** Validasi */
          if (this.keteranganValidasi) {
            this.form.idKeteranganValidasi = this.keteranganValidasi
          }
        })
        .catch(err => {
          this.$router.push({
            name: 'kerjasama.jenisDokumen'
          })
          this.$toast.error('Terjadi kesalahan pada sistem')
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get List data Validasi
     */
    async getListDataValidasi_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=validasi`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataValidasi = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Validasi Data
     */
    async validasiData() {
      console.log('form: ', this.form)
      const bodyFormData = new FormData()
      bodyFormData.append('idSk', this.form.idSk)
      bodyFormData.append('idStatusValidasi', this.form.idStatusValidasi)
      bodyFormData.append(
        'keteranganValidasi',
        this.form.idStatusValidasi === 2 ? this.form.idKeteranganValidasi : null
      )

      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/validateSk`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$router.push({ name: 'menuKhusus.verifikasiDokumen' })
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('Data gagal divalidasi')
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => Dokumen KS
     */
    async getDokumenKs_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenSk?lokasiDokumenSk=${this.dataSk.lokasiDokumenSk}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataDokumenKs = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * SHOW => Dokumen SK
     */
    async showDokumenKs() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenSk?lokasiDokumenSk=${this.dataSk.lokasiDokumenSk}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Dokumen_SK_${this.dataSk.nomorSuratKuasa}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get data Detail Kerjasama SK */
      this.getDataTransaksiSk_(), this.getListDataValidasi_()
    }
  },
  data() {
    return {
      // Others
      statusValidasi: null,
      keteranganValidasi: null,
      dataDokumenKs: null,
      showCetakan: null,
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      // List Data
      listInputMitra: [],
      listDataValidasi: [],
      // Form
      form: {
        idSk: null,
        // nomorSuratKuasa: null,
        tanggalSurat: null,
        namaPihakSatu: null,
        jabatanPihakSatu: null,
        instansiPihakSatu: null,
        // alamatPihakSatu: null,
        nipPihakSatu: null,
        namaPihakDua: null,
        jabatanPihakDua: null,
        instansiPihakDua: null,
        // alamatPihakDua: null,
        nipPihakDua: null,
        namaKegiatan: null,
        judulKegiatan: null,
        tanggalAwalKegiatan: null,
        tanggalAkhirKegiatan: null,
        tempatKegiatan: null,
        lokasiDokumenSk: null,
        // Validasi
        idStatusValidasi: null,
        idKeteranganValidasi: null
      },
      // Data SK
      dataSk: {}
    }
  }
}
</script>
